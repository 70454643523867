// Typography
// ----------
//

@import "../settings";

h1 {
  @include breakpoint(small only) {
    margin: 0.5rem 0 1rem 0;
    line-height: 32px;
  }

  @include breakpoint(medium) {
    margin: 1rem 0;
    line-height: 40px;
  }
}

h2 {
  @include breakpoint(small only) {
    margin: 1rem 0 0.75rem 0;
    line-height: 20px;
  }

  @include breakpoint(medium) {
    margin: 1rem 0 0.5rem 0;
    line-height: 28px;
  }
}

h2.heading-small {
  @include breakpoint(small only) {
    margin: 1rem 0 0.5rem;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    font-weight: $global-weight-normal;
  }
}

h3 {
  margin: 1rem 0;
  line-height: 24px;
}

li {
  line-height: 24px;
}

ul.list,
ol.list {
  li {
    margin-bottom: 1rem;
  }
}

// Basic paragraph and variations of it

p {
  line-height: 20px;
}

p.lead {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  font-weight: $global-weight-bold;
}

.footnote {
  font-size: 13px;
  line-height: 16px;
}

p.help-text {
  // Used as help or error with inputs
  line-height: 16px;
}

// -----------

.separator {
  color: $light-gray;
}

a {
  color: $primary-color-dark;

  &:hover {
    color: darken($primary-color-dark, 8%);
  }
}

@media print {
  a[href]::after {
    display: none;
  }
}

.stat {
  font-weight: $global-weight-bolder;
  line-height: 32px;
  color: $darker-gray;
  white-space: nowrap;

  @include breakpoint(medium) {
    font-size: 36px; // Small breakpoint in settings
    line-height: 40px;
  }
}

// Bubble with numbers in it

.badge {
  min-height: 1rem;
  border-radius: 8px;
  line-height: 0;
  font-weight: $global-weight-bold;

  &.alert {
    color: $white;
  }
}

// List with no bullets & border up & down

.list-bordered {
  margin: 2rem 0;
  list-style-type: none;

  li {
    padding: 0;
    border-bottom: 1px solid $light-gray;

    &:first-of-type {
      border-top: 1px solid $light-gray;
    }

    > a {
      display: inline-block;
      padding: 1rem 0;
      line-height: 24px;
      color: $body-font-color;
      transition: $global-transition;

      &:hover {
        text-decoration: none;
        color: $primary-color-dark;
      }
    }
  }
}

// List with no bullets

.list-unstyled {
  margin: 0;
  list-style-type: none;

  li {
    padding: 0.25rem 0 0.25rem 0;
  }
}
