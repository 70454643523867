//  Paging, based on react-paginate
//  -------------------------------
//

/*
containerClassName="react-paginate"
pageClassName="react-paginate-page"
pageLinkClassName="react-paginate-page-link"
activeClassName="react-paginate-page-active"
previousClassName="react-paginate-page-previous"
nextClassName="react-paginate-page-next"
previousLinkClassName="react-paginate-page-previous-link"
nextLinkClassName="react-paginate-page-next-link"
disabledClassName="react-paginate-page-disabled"
*/

.react-paginate {
  display: flex;
  margin: 0;
  justify-content: center;
  list-style-type: none;

  li {
    a {
      display: block;
      margin: 0.25rem;
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
      width: 2.5rem;
      font-size: 13px;
      line-height: 16px;
      text-align: center;
      color: $body-font-color;
      font-weight: $global-weight-bold;
      border: 1px solid $primary-color;
      border-radius: $global-radius;
      transition: $global-transition;

      &:hover,
      &:focus,
      &:active {
        text-decoration: none;
        transform: scale(1.1);
      }

      &:focus {
        outline: none;
      }
    }

    &.react-paginate-page-active {
      a {
        color: $white;
        background-color: $primary-color;
      }
    }

    &.react-paginate-page-disabled {
      opacity: 0.3;
    }
  }
}
