// Transient callout
// -----------------
//

@import "../settings";

.callout-transient-header {
  border-bottom: 1px solid $light-gray;
  padding-bottom: 0.5rem;

  h3 {
    margin: 0;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.2px;

    small {
      font-size: 10px;
    }
  }
}

.callout-transient-body {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.callout-transient {
  padding: 0.75rem 1rem 1rem 1rem;
  background-color: $white;

  @include breakpoint(small only) {
    margin: 0.5rem -0.5rem 1.5rem -0.5rem;
    border-top: 1px solid $light-gray;
    border-bottom: 1px solid $light-gray;
  }

  @include breakpoint(medium) {
    margin: 0 0 1rem 0;
    border: 1px solid $light-gray;
    border-radius: $global-radius;
  }

  .input-group {
    margin: 0;
  }

  .input-group-button {
    input {
      padding: 0;
      width: 2.5rem;
      font-size: 28px;
      line-height: 0;
      color: $primary-color-dark;
    }
  }

  label {
    margin-top: 4px;
    font-size: 13px;
  }

  .button {
    margin: 0;
    height: 40px;
    font-size: 14px;

    &.hollow {
      // Stepper buttons and "activate" state for edit/save button
      color: $primary-color-dark;
      background-color: $primary-color-light;

      &:hover,
      &:active,
      &:focus {
        background-color: $primary-color-light;
      }
    }

    &.meter-submit {
      // Edit/save button
      margin-top: 1rem;
      font-size: 13px;
      text-transform: uppercase;

      @include breakpoint(small only) {
        width: 100%;
      }

      @include breakpoint(medium) {
        min-width: 10rem; // Needs to be similar to input width for some rhythm
      }
    }
  }

  p.help-text {
    margin: 0.5rem 0 0 0;
  }
}

.meter-readings-wrapper {
  display: flex;
  margin-top: 1rem;
  text-align: center;

  @include breakpoint(small only) {
    justify-content: center;
    // flex-wrap: wrap; // Make wrap if input dynamic width is done

    label.unit-with-superscript {
      margin-right: -1rem;
    }
  }
}

.meter-input-wrapper {
  @include breakpoint(medium) {
    max-width: 15rem; // Hack for MS Edge because it ignores width on input element
  }
}

input.meter-input {
  margin: 0 -1px;
  padding: 0;
  font-weight: $global-weight-bold;
  font-size: 21px;
  text-align: center;
  height: 40px;
  border-color: $primary-color;
  z-index: 10;

  &.meter-estimate {
    color: $dark-gray;
  }

  @include breakpoint(small only) {
    width: 100%; // This needs to be manipulated with JS. Min-width 8rem & grow from there
  }

  @include breakpoint(medium) {
    width: 10rem; // Dynamic width do won't work for inputs bc input does not know it's content
    min-width: 10rem;
  }
}

.meter-amount-wrapper {
  // width: 100%;
  min-width: 5rem;
}

.meter-amount {
  padding: 7px 0;
  height: 40px;
  font-size: 21px;
  font-weight: $global-weight-bold;

  &.meter-estimate {
    color: $dark-gray;
  }

  @include breakpoint(small only) {
    margin-left: 1rem;
  }

  @include breakpoint(medium) {
    margin: 0 1rem;
  }
}
