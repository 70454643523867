//  Tooltip based on React-Tippy (https://github.com/jafin/react-tippy)
//  -------------------------------------------------------------------
//

.tippy-touch {
  cursor: pointer !important;
}

.tippy-notransition {
  transition: none !important;
}

.tippy-popper {
  z-index: 9999;
  max-width: 400px;
  transition-timing-function: cubic-bezier(0.1, 0.4, 0.5, 0.6);
  pointer-events: none;
  outline: 0;
  perspective: 800px;
}

.tippy-popper.html-template {
  max-width: 96%;
  max-width: calc(100% - 24px);
}

//  Tippy sizes

.tippy-popper[x-placement^="top"] [x-arrow] {
  bottom: -8px;
  //margin: 0 4px;
  border-top: 8px solid $darker-gray;
  border-right: 8px solid transparent;
  border-left: 8px solid transparent;
}

.tippy-popper[x-placement^="top"] [x-arrow].arrow-small {
  bottom: -4px;
  border-top: 4px solid $darker-gray;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.tippy-popper[x-placement^="top"] [x-arrow].arrow-big {
  bottom: -12px;
  border-top: 12px solid $darker-gray;
  border-right: 12px solid transparent;
  border-left: 12px solid transparent;
}

.tippy-popper[x-placement^="top"] [x-circle] {
  transform-origin: 0 33%;
}

.tippy-popper[x-placement^="top"] [x-circle].enter {
  transform: scale(1) translate(-50%, -55%);
  opacity: 1;
}

.tippy-popper[x-placement^="top"] [x-circle].leave {
  transform: scale(0.15) translate(-50%, -50%);
  opacity: 0;
}

.tippy-popper[x-placement^="top"] .tippy-tooltip.light-theme [x-circle] {
  background-color: $white;
}

.tippy-popper[x-placement^="top"] .tippy-tooltip.light-theme [x-arrow] {
  border-top: 8px solid $white;
  border-right: 8px solid transparent;
  border-left: 8px solid transparent;
}

.tippy-popper[x-placement^="top"] .tippy-tooltip.light-theme [x-arrow].arrow-small {
  border-top: 4px solid $white;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.tippy-popper[x-placement^="top"] .tippy-tooltip.light-theme [x-arrow].arrow-big {
  border-top: 12px solid $white;
  border-right: 12px solid transparent;
  border-left: 12px solid transparent;
}

.tippy-popper[x-placement^="top"] .tippy-tooltip.transparent-theme [x-circle] {
  background-color: $darker-gray;
}

.tippy-popper[x-placement^="top"] .tippy-tooltip.transparent-theme [x-arrow] {
  border-top: 8px solid $darker-gray;
  border-right: 8px solid transparent;
  border-left: 8px solid transparent;
}

.tippy-popper[x-placement^="top"] .tippy-tooltip.transparent-theme [x-arrow].arrow-small {
  border-top: 4px solid $darker-gray;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.tippy-popper[x-placement^="top"] .tippy-tooltip.transparent-theme [x-arrow].arrow-big {
  border-top: 12px solid $darker-gray;
  border-right: 12px solid transparent;
  border-left: 12px solid transparent;
}

.tippy-popper[x-placement^="top"] [data-animation="perspective"] {
  transform-origin: bottom;
}

.tippy-popper[x-placement^="top"] [data-animation="perspective"].enter {
  transform: translateY(-12px) rotateX(0);
  opacity: 1;
}

.tippy-popper[x-placement^="top"] [data-animation="perspective"].leave {
  transform: translateY(0) rotateX(90deg);
  opacity: 0;
}

.tippy-popper[x-placement^="top"] [data-animation="fade"].enter {
  transform: translateY(-12px);
  opacity: 1;
}

.tippy-popper[x-placement^="top"] [data-animation="fade"].leave {
  transform: translateY(-12px);
  opacity: 0;
}

.tippy-popper[x-placement^="top"] [data-animation="shift"].enter {
  transform: translateY(-12px);
  opacity: 1;
}

.tippy-popper[x-placement^="top"] [data-animation="shift"].leave {
  transform: translateY(0);
  opacity: 0;
}

.tippy-popper[x-placement^="top"] [data-animation="scale"].enter {
  transform: translateY(-12px) scale(1);
  opacity: 1;
}

.tippy-popper[x-placement^="top"] [data-animation="scale"].leave {
  transform: translateY(0) scale(0);
  opacity: 0;
}

.tippy-popper[x-placement^="bottom"] [x-arrow] {
  top: -8px;
  margin: 0 8px;
  border-right: 8px solid transparent;
  border-bottom: 8px solid $medium-gray;
  border-left: 8px solid transparent;
}

.tippy-popper[x-placement^="bottom"] [x-arrow].arrow-small {
  top: -4px;
  border-right: 4px solid transparent;
  border-bottom: 4px solid $medium-gray;
  border-left: 4px solid transparent;
}

.tippy-popper[x-placement^="bottom"] [x-arrow].arrow-big {
  top: -12px;
  border-right: 12px solid transparent;
  border-bottom: 12px solid $medium-gray;
  border-left: 12px solid transparent;
}

.tippy-popper[x-placement^="bottom"] [x-circle] {
  transform-origin: 0 -50%;
}

.tippy-popper[x-placement^="bottom"] [x-circle].enter {
  transform: scale(1) translate(-50%, -45%);
  opacity: 1;
}

.tippy-popper[x-placement^="bottom"] [x-circle].leave {
  transform: scale(0.15) translate(-50%, -5%);
  opacity: 0;
}

.tippy-popper[x-placement^="bottom"] .tippy-tooltip.light-theme [x-circle] {
  background-color: $white;
}

.tippy-popper[x-placement^="bottom"] .tippy-tooltip.light-theme [x-arrow] {
  border-right: 8px solid transparent;
  border-bottom: 8px solid $white;
  border-left: 8px solid transparent;
}

.tippy-popper[x-placement^="bottom"] .tippy-tooltip.light-theme [x-arrow].arrow-small {
  border-right: 4px solid transparent;
  border-bottom: 4px solid $white;
  border-left: 4px solid transparent;
}

.tippy-popper[x-placement^="bottom"] .tippy-tooltip.light-theme [x-arrow].arrow-big {
  border-right: 12px solid transparent;
  border-bottom: 12px solid $white;
  border-left: 12px solid transparent;
}

.tippy-popper[x-placement^="bottom"] .tippy-tooltip.transparent-theme [x-circle] {
  background-color: $dark-gray;
}

.tippy-popper[x-placement^="bottom"] .tippy-tooltip.transparent-theme [x-arrow] {
  border-right: 8px solid transparent;
  border-bottom: 8px solid $dark-gray;
  border-left: 8px solid transparent;
}

.tippy-popper[x-placement^="bottom"] .tippy-tooltip.transparent-theme [x-arrow].arrow-small {
  border-right: 4px solid transparent;
  border-bottom: 4px solid $dark-gray;
  border-left: 4px solid transparent;
}

.tippy-popper[x-placement^="bottom"] .tippy-tooltip.transparent-theme [x-arrow].arrow-big {
  border-right: 12px solid transparent;
  border-bottom: 12px solid $dark-gray;
  border-left: 12px solid transparent;
}

.tippy-popper[x-placement^="bottom"] [data-animation="perspective"] {
  transform-origin: top;
}

.tippy-popper[x-placement^="bottom"] [data-animation="perspective"].enter {
  transform: translateY(12px) rotateX(0);
  opacity: 1;
}

.tippy-popper[x-placement^="bottom"] [data-animation="perspective"].leave {
  transform: translateY(0) rotateX(-90deg);
  opacity: 0;
}

.tippy-popper[x-placement^="bottom"] [data-animation="fade"].enter {
  transform: translateY(12px);
  opacity: 1;
}

.tippy-popper[x-placement^="bottom"] [data-animation="fade"].leave {
  transform: translateY(12px);
  opacity: 0;
}

.tippy-popper[x-placement^="bottom"] [data-animation="shift"].enter {
  transform: translateY(12px);
  opacity: 1;
}

.tippy-popper[x-placement^="bottom"] [data-animation="shift"].leave {
  transform: translateY(0);
  opacity: 0;
}

.tippy-popper[x-placement^="bottom"] [data-animation="scale"].enter {
  transform: translateY(12px) scale(1);
  opacity: 1;
}
.tippy-popper[x-placement^="bottom"] [data-animation="scale"].leave {
  transform: translateY(0) scale(0);
  opacity: 0;
}

.tippy-popper[x-placement^="left"] [x-arrow] {
  right: -8px;
  margin: 6px 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid $medium-gray;
}

.tippy-popper[x-placement^="left"] [x-arrow].arrow-small {
  right: -4px;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-left: 4px solid $medium-gray;
}

.tippy-popper[x-placement^="left"] [x-arrow].arrow-big {
  right: -12px;
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  border-left: 12px solid $medium-gray;
}

.tippy-popper[x-placement^="left"] [x-circle] {
  transform-origin: 50% 0;
}

.tippy-popper[x-placement^="left"] [x-circle].enter {
  transform: scale(1) translate(-50%, -50%);
  opacity: 1;
}

.tippy-popper[x-placement^="left"] [x-circle].leave {
  transform: scale(0.15) translate(-50%, -50%);
  opacity: 0;
}

.tippy-popper[x-placement^="left"] .tippy-tooltip.light-theme [x-circle] {
  background-color: $white;
}

.tippy-popper[x-placement^="left"] .tippy-tooltip.light-theme [x-arrow] {
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid $white;
}

.tippy-popper[x-placement^="left"] .tippy-tooltip.light-theme [x-arrow].arrow-small {
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-left: 4px solid $white;
}

.tippy-popper[x-placement^="left"] .tippy-tooltip.light-theme [x-arrow].arrow-big {
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  border-left: 12px solid $white;
}

.tippy-popper[x-placement^="left"] .tippy-tooltip.transparent-theme [x-circle] {
  background-color: $dark-gray;
}

.tippy-popper[x-placement^="left"] .tippy-tooltip.transparent-theme [x-arrow] {
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid $dark-gray;
}

.tippy-popper[x-placement^="left"] .tippy-tooltip.transparent-theme [x-arrow].arrow-small {
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-left: 4px solid $dark-gray;
}

.tippy-popper[x-placement^="left"] .tippy-tooltip.transparent-theme [x-arrow].arrow-big {
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  border-left: 12px solid $dark-gray;
}

.tippy-popper[x-placement^="left"] [data-animation="perspective"] {
  transform-origin: right;
}

.tippy-popper[x-placement^="left"] [data-animation="perspective"].enter {
  transform: translateX(-12px) rotateY(0);
  opacity: 1;
}

.tippy-popper[x-placement^="left"] [data-animation="perspective"].leave {
  transform: translateX(0) rotateY(-90deg);
  opacity: 0;
}

.tippy-popper[x-placement^="left"] [data-animation="fade"].enter {
  transform: translateX(-12px);
  opacity: 1;
}

.tippy-popper[x-placement^="left"] [data-animation="fade"].leave {
  transform: translateX(-12px);
  opacity: 0;
}

.tippy-popper[x-placement^="left"] [data-animation="shift"].enter {
  transform: translateX(-12px);
  opacity: 1;
}

.tippy-popper[x-placement^="left"] [data-animation="shift"].leave {
  transform: translateX(0);
  opacity: 0;
}

.tippy-popper[x-placement^="left"] [data-animation="scale"].enter {
  transform: translateX(-12px) scale(1);
  opacity: 1;
}

.tippy-popper[x-placement^="left"] [data-animation="scale"].leave {
  transform: translateX(0) scale(0);
  opacity: 0;
}

.tippy-popper[x-placement^="right"] [x-arrow] {
  left: -8px;
  margin: 6px 0;
  border-top: 8px solid transparent;
  border-right: 8px solid $medium-gray;
  border-bottom: 8px solid transparent;
}

.tippy-popper[x-placement^="right"] [x-arrow].arrow-small {
  left: -4px;
  border-top: 4px solid transparent;
  border-right: 4px solid $medium-gray;
  border-bottom: 4px solid transparent;
}

.tippy-popper[x-placement^="right"] [x-arrow].arrow-big {
  left: -12px;
  border-top: 12px solid transparent;
  border-right: 12px solid $medium-gray;
  border-bottom: 12px solid transparent;
}

.tippy-popper[x-placement^="right"] [x-circle] {
  transform-origin: -50% 0;
}

.tippy-popper[x-placement^="right"] [x-circle].enter {
  transform: scale(1) translate(-50%, -50%);
  opacity: 1;
}

.tippy-popper[x-placement^="right"] [x-circle].leave {
  transform: scale(0.15) translate(-50%, -50%);
  opacity: 0;
}

.tippy-popper[x-placement^="right"] .tippy-tooltip.light-theme [x-circle] {
  background-color: $white;
}

.tippy-popper[x-placement^="right"] .tippy-tooltip.light-theme [x-arrow] {
  border-top: 8px solid transparent;
  border-right: 8px solid $white;
  border-bottom: 8px solid transparent;
}

.tippy-popper[x-placement^="right"] .tippy-tooltip.light-theme [x-arrow].arrow-small {
  border-top: 4px solid transparent;
  border-right: 4px solid $white;
  border-bottom: 4px solid transparent;
}

.tippy-popper[x-placement^="right"] .tippy-tooltip.light-theme [x-arrow].arrow-big {
  border-top: 12px solid transparent;
  border-right: 12px solid $white;
  border-bottom: 12px solid transparent;
}

.tippy-popper[x-placement^="right"] .tippy-tooltip.transparent-theme [x-circle] {
  background-color: $dark-gray;
}

.tippy-popper[x-placement^="right"] .tippy-tooltip.transparent-theme [x-arrow] {
  border-top: 8px solid transparent;
  border-right: 8px solid $dark-gray;
  border-bottom: 8px solid transparent;
}

.tippy-popper[x-placement^="right"] .tippy-tooltip.transparent-theme [x-arrow].arrow-small {
  border-top: 4px solid transparent;
  border-right: 4px solid $dark-gray;
  border-bottom: 4px solid transparent;
}

.tippy-popper[x-placement^="right"] .tippy-tooltip.transparent-theme [x-arrow].arrow-big {
  border-top: 12px solid transparent;
  border-right: 12px solid $dark-gray;
  border-bottom: 12px solid transparent;
}

.tippy-popper[x-placement^="right"] [data-animation="perspective"] {
  transform-origin: left;
}

.tippy-popper[x-placement^="right"] [data-animation="perspective"].enter {
  transform: translateX(12px) rotateY(0);
  opacity: 1;
}

.tippy-popper[x-placement^="right"] [data-animation="perspective"].leave {
  transform: translateX(0) rotateY(90deg);
  opacity: 0;
}

.tippy-popper[x-placement^="right"] [data-animation="fade"].enter {
  transform: translateX(12px);
  opacity: 1;
}

.tippy-popper[x-placement^="right"] [data-animation="fade"].leave {
  transform: translateX(12px);
  opacity: 0;
}

.tippy-popper[x-placement^="right"] [data-animation="shift"].enter {
  transform: translateX(12px);
  opacity: 1;
}

.tippy-popper[x-placement^="right"] [data-animation="shift"].leave {
  transform: translateX(0);
  opacity: 0;
}

.tippy-popper[x-placement^="right"] [data-animation="scale"].enter {
  transform: translateX(12px) scale(1);
  opacity: 1;
}

.tippy-popper[x-placement^="right"] [data-animation="scale"].leave {
  transform: translateX(0) scale(0);
  opacity: 0;
}

.tippy-popper .tippy-tooltip.transparent-theme {
  background-color: $dark-gray;
}

.tippy-popper .tippy-tooltip.transparent-theme[data-animatefill] {
  background-color: transparent;
}

.tippy-popper .tippy-tooltip.light-theme {
  color: $medium-gray;
  background-color: $white;
  box-shadow: $global-shadow;
}

.tippy-popper .tippy-tooltip.light-theme[data-animatefill] {
  background-color: transparent;
}

.tippy-tooltip {
  font-size: 14px;
  position: relative;
  padding: 0.75rem 1rem;
  text-align: left;
  color: $white;
  border-radius: $global-radius;
  background-color: $darker-gray;
  will-change: transform;
}

.tippy-tooltip--small {
  font-size: 13px;
  padding: 0.25rem 0.5rem;
}

.tippy-tooltip--big {
  font-size: 16px;
  padding: 2rem;
}

.tippy-tooltip[data-animatefill] {
  overflow: hidden;
  background-color: transparent;
}

.tippy-tooltip[data-interactive] {
  pointer-events: auto;
}

.tippy-tooltip[data-inertia] {
  transition-timing-function: cubic-bezier(0.3, 1, 0.36, 0.5);
}

.tippy-tooltip [x-arrow] {
  position: absolute;
  width: 0;
  height: 0;
}

.tippy-tooltip [x-circle] {
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 50%;
  overflow: hidden;
  width: 130%;
  width: calc(110% + 2rem);
  transition: all ease;
  border-radius: 50%;
  background-color: $darker-gray;
  will-change: transform;
}

.tippy-tooltip [x-circle]::before {
  float: left;
  padding-top: 90%;
  content: "";
}

@media (max-width: 450px) {
  .tippy-popper {
    max-width: 96%;
    max-width: calc(100% - 20px);
  }
}
