@keyframes slideOutLeft {
  0% {
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateX(-2000px);
  }
}

.slideOutLeft {
  animation-name: slideOutLeft;
}
