//  Foundation & custom components
//  ------------------------------
//

@charset 'utf-8';

@import "./settings.scss";
@import "foundation-sites/scss/foundation.scss";

@include foundation-global-styles;
// @include foundation-grid;
// @include foundation-flex-grid;
@include foundation-xy-grid-classes;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
// @include foundation-range-input;
// @include foundation-accordion;
// @include foundation-accordion-menu;
@include foundation-badge;
// @include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-card;
@include foundation-close-button;
@include foundation-menu;
@include foundation-menu-icon;
// @include foundation-drilldown-menu;
@include foundation-dropdown;
// @include foundation-dropdown-menu;
@include foundation-responsive-embed;
@include foundation-label;
// @include foundation-media-object;
@include foundation-off-canvas;
// @include foundation-orbit;
// @include foundation-pagination;
// @include foundation-progress-bar;
// @include foundation-slider;
// @include foundation-sticky;
@include foundation-reveal;
// @include foundation-switch;
@include foundation-table;
@include foundation-tabs;
// @include foundation-thumbnail;
@include foundation-title-bar;
// @include foundation-tooltip;
// @include foundation-top-bar;
@include foundation-visibility-classes;
@include foundation-float-classes;
@include foundation-flex-classes;
@include foundation-prototype-classes;

// @include motion-ui-transitions;
// @include motion-ui-animations;

// Styling Common for App & Public Page
// ------------------------------------

@import "atoms/typography.scss";
@import "atoms/inputs.scss";
@import "atoms/buttons.scss";
@import "atoms/tables.scss";
// @import "atoms/definitionlist.scss";
@import "atoms/tabs.scss";

// Styling for App
// ---------------

@import "molecules/actionrow.scss";
@import "molecules/headings.scss";
@import "molecules/card.scss";
@import "molecules/callout-transient.scss";
@import "molecules/empty-state.scss";
@import "molecules/utilities.scss";

@import "vendor/react-paginate.scss";
@import "vendor/react-select.scss";
@import "vendor/switch.scss";
@import "vendor/react-modal.scss";
@import "vendor/react-tippy.scss";

// Sandbox for Playing With Things
// -------------------------------

@import "sandbox.scss";

// Fix for animations
body {
  /* Addresses a small issue in webkit: http://bit.ly/NEdoDq */
  /* eslint:disable-next-line */
  -webkit-backface-visibility: hidden;
}

// Fix for screens with notches
html {
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
}

// Prevent blank last page when printing
@media print {
  html,
  body {
    height: auto !important;
    overflow: visible !important;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
    margin: 0 !important;
    padding: 0 !important;
  }

  // Prevent blank last page
  body::after {
    content: "";
    display: block;
    height: 0;
    clear: both;
  }

  // Remove fixed positioning that can cause spacing issues
  .off-canvas-content {
    margin: 0 !important;
    padding: 0 !important;
    transform: none !important;
    min-height: 0 !important;
  }

  // Remove any margins that might push content to new page
  * {
    margin-bottom: 0 !important;
  }
}
