@keyframes slideInDown {
  0% {
    opacity: 0;
    transform: translateY(-2000px);
  }

  100% {
    transform: translateY(0);
  }
}

.slideInDown {
  animation-name: slideInDown;
}
