$animate-scss-speed: 0.2s !default;

@mixin animate($animation: none, $speed: $animate-scss-speed) {
  @if $animation != none {
    animation-name: $animation;
  }

  animation-duration: $speed;
  animation-fill-mode: both;
}
