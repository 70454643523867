//  Switch
//  ------
//
// Comes from this place: http://aaronshaf.github.io/react-toggle/

.react-toggle {
  touch-action: pan-x;
  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  //-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  transition: opacity 0.25s;
}

.react-toggle-track {
  width: 48px;
  height: 28px;
  padding: 0;
  border-radius: 30px;
  background-color: $light-gray;
  transition: all 0.2s ease;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: $dark-gray;
}

.react-toggle--checked .react-toggle-track {
  background-color: $success-color;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: $success-color;
}

.react-toggle-track-check {
  position: absolute;
  width: 14px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  left: 8px;
  opacity: 0;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
  opacity: 1;
  transition: opacity 0.25s ease;
}

.react-toggle-track-x {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  right: 10px;
  opacity: 1;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
  opacity: 0;
}

.react-toggle-thumb {
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: 1px;
  left: 1px;
  width: 26px;
  height: 26px;
  border: 1px solid $light-gray;
  border-radius: 50%;
  background-color: $white;
  box-sizing: border-box;
  transition: all 0.09s ease-in;
}

.react-toggle--checked .react-toggle-thumb {
  left: 24px;
  border-color: $success-color;
}

/*

No need for glowing button. Keyboard accessibility bad, though…

.react-toggle--focus .react-toggle-thumb {
  box-shadow: 0px 0px 2px 3px $primary-color;
}

.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
  box-shadow: 0px 0px 5px 5px $primary-color;
}
*/

//  Switch container (not part of a component)
//  ------------------------------------------

.switch-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 4px 0;
  padding: 8px 0;
  border-bottom: 1px solid $light-gray;

  &:first-of-type {
    border-top: 1px solid $light-gray;
  }

  .react-toggle {
    margin-left: 8px;
  }

  .switch-label {
    font-size: $form-label-font-size;
  }
}
