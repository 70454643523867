// Sandbox for playing around with things
// --------------------------------------
//

@import "./settings";

body,
#root,
#TranslationProvider {
  min-height: 100vh;
  // outline: 1px dashed yellowgreen;
}

.placeholder-parent {
  display: flex;
  align-items: stretch;
  align-content: stretch;
  justify-content: center;
  margin: 2rem;
  padding: 2rem;
  // min-height: 100%;
  // outline: 1px dotted orangered;
}

.placeholder-child {
  align-self: center;
  flex-basis: 100%;
  // min-height: 19rem;
  // flex: 1;
  // outline: 1px dotted darkviolet;
}

.chart-container {
  margin: 2rem 0;
}

code {
  margin: 0 0 1rem 0;
  font-size: 13px;

  &.errocode {
    display: block;
    text-align: left;
    overflow-y: auto;
    max-height: 6rem;
  }
}
