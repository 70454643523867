// Utilities
// ---------
//

@import "../settings";

.margin-horizontal-auto {
  margin-left: auto;
  margin-right: auto;
}

// mb-0 to mb-4 CSS classes
@for $i from 0 through 4 {
  .mb-#{$i} {
    margin-bottom: #{$i}rem;
  }
}

// mt-0 to mt-5 CSS classes
@for $i from 0 through 5 {
  .mt-#{$i} {
    margin-top: #{$i}rem;
  }
}

// mr-0 to mr-5 CSS classes
@for $i from 0 through 5 {
  .mr-#{$i} {
    margin-right: #{$i}rem;
  }
}

// ml-0 to ml-5 CSS classes
@for $i from 0 through 5 {
  .ml-#{$i} {
    margin-left: #{$i}rem;
  }
}

.margin-top-05 {
  margin-top: 0.5rem !important;
}

.flex-container {
  display: flex;
}

.flex-align-center {
  display: flex;
  align-items: center;
}

.flex-justify-center {
  display: flex;
  justify-content: center;
}

.border-transparent {
  border-color: transparent !important;
}

.border-bottom {
  border-bottom: 1px solid $prototype-border-color !important;
}

.border-top {
  border-top: 1px solid $prototype-border-color !important;
}

// Color utilities

.text-alert {
  color: $alert-color;
}

.text-success {
  color: $success-color;
}

.text-warning {
  color: $warning-color;
}

.text-primary-color {
  color: $primary-color;
}

.text-primary-color-dark {
  color: $primary-color-dark;
}

.text-secondary-color {
  color: $secondary-color;
}

.text-white {
  color: $white;
}

.text-gray {
  color: $dark-gray;
}

.text-gray-light {
  color: $light-gray;
}

.text-bold {
  font-weight: $global-weight-bold;
}

.text-clickable {
  cursor: pointer;
}

.bg-color-white {
  background-color: $white;
}

.bg-success {
  background-color: $success-color;
}

.bg-alert {
  background-color: $alert-color;
}

.bg-yellow {
  background-color: #ffce00;
}

.bg-primary {
  background-color: $primary-color;
}

.bg-secondary {
  background-color: $secondary-color;
}

.bg-secondary-light {
  background-color: $secondary-color-light;
}

.bg-light-gray {
  background-color: $light-gray;
}

.bg-inactive {
  background-color: map-get($foundation-palette, inactive);
}

.bg-warning {
  background-color: $warning-color;
}

.bg-warning-light {
  background-color: $warning-color-light;
}

.bg-color-primary {
  background-color: $primary-color;
}

.bg-color-primary-light {
  background-color: $primary-color-light;
}

.bg-color-primary-dark {
  background-color: $primary-color-dark;
}

.bg-color-secondary-light {
  background-color: $secondary-color-light;
}

.bg-color-secondary {
  background-color: $secondary-color;
}

.bg-color-secondary-dark {
  background-color: $secondary-color-dark;
}

.bg-color-alert {
  background-color: $alert-color;
}

.bg-color-alert-light {
  background-color: $alert-color-light;
}
.bg-color-alert-dark {
  background-color: $warning-color-dark;
}

.bg-color-warning {
  background-color: $warning-color;
}

.bg-color-warning-light {
  background-color: $warning-color-light;
}

.bg-color-warning-dark {
  background-color: $warning-color-dark;
}

.bg-color-gray-lighter {
  background-color: $lighter-gray;
}

.bg-color-gray-light {
  background-color: $light-gray;
}

.bg-color-gray-medium {
  background-color: $medium-gray;
}

.bg-color-gray-dark {
  background-color: $dark-gray;
}

.bg-color-gray-darker {
  background-color: $darker-gray;
}

.bg-color-black {
  background-color: $black;
}

/*
$alert-color-dark: darken($alert-color, 15%);
$alert-color-light: lighten($alert-color, 43%);

$warning-color-dark: darken($warning-color, 15%);
$warning-color-light: lighten($warning-color, 48%);*/

// TODO: not sure about this
.height-25 {
  height: 2.5rem !important;
}

.horizontal-scroll-wrapper {
  overflow-y: auto;
}
