// Inputs
// ------
//

@import "../settings";

label {
  line-height: 16px;
  color: $form-label-color;

  .button-group {
    margin-top: 4px;
  }

  &.required {
    .input-label {
      &::before {
        content: "*";
        margin-right: 2px;
        font-weight: $global-weight-bold;
        color: $alert-color;
      }
    }
  }
}

.label-required {
  &::before {
    content: "*";
    margin-right: 2px;
    font-weight: $global-weight-bold;
    color: $alert-color;
  }
}

input[type="text"],
input[type="number"],
input[type="date"],
input[type="password"],
.input-group {
  margin-top: 4px;
  // height: 2.25rem;

  &:disabled,
  &[readonly] {
    box-shadow: none;
    border: none;
    border: 1px dashed $light-gray;

    &:focus {
      border: 1px dashed $light-gray;
      box-shadow: none;
    }
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.input-group {
  & > input {
    margin-top: 0;
  }
}

select,
textarea {
  margin-top: 4px;
}

select {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
}

label.checkbox {
  margin-bottom: 1rem;

  input[type="checkbox"] {
    margin: 0 4px 0 0;
  }
}

// Styling for highlighting error & warning inputs

input,
select,
textarea {
  transition: $global-transition;

  &.input-alert {
    border-color: $alert-color;
    background-color: $alert-color-light;

    &:focus {
      border-color: $alert-color-dark;
    }
  }

  &.input-warning {
    border-color: $warning-color !important;
    background-color: $warning-color-light !important;

    &:focus {
      border-color: $warning-color-dark;
    }
  }
}
