//  Tabs
//  ----
//

@import "../settings";

.tabs {
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
  justify-content: center;
  text-align: center;
  border-bottom: 1px solid $primary-color;

  @include breakpoint(small only) {
    overflow-y: auto;
  }
}

.tabs-title {
  float: none;
  border-top: 1px solid transparent;
  border-right: 1px solid transparent;
  border-left: 1px solid transparent;
  border-radius: $global-radius $global-radius 0 0;
  transition: border-width ease-in 0.05s;

  &:hover {
    border-bottom: 4px solid $light-gray;
  }

  &.is-active {
    border-top: 1px solid $light-gray;
    border-right: 1px solid $light-gray;
    border-left: 1px solid $light-gray;
    border-bottom: 4px solid $primary-color;
  }

  a,
  span {
    // Mobile font size in settings
    border: none;
    cursor: pointer;
    line-height: 1;
    display: block;

    @include breakpoint(medium) {
      padding: 0.5rem 1.5rem;
      font-size: 16px;
    }

    &:hover,
    &:focus {
      background-color: transparent;
      text-decoration: none;
    }
  }
}
