// Icons
// -----
//

@import "src/gfx/scss/_settings.scss";

.icon {
  display: inline-block;
  vertical-align: middle;
  fill: currentColor;

  use {
    all: inherit;
  }
}

.icon.small {
  width: 16px;
  height: 16px;
}

.icon.standard {
  width: 24px;
  height: 24px;
}

.icon.medium {
  width: 32px;
  height: 32px;
}

.icon.large {
  width: 48px;
  height: 48px;
}

.icon.x-large {
  width: 64px;
  height: 64px;
}

.icon.full {
  width: 100%;
  height: 100%;
}

.icon.success {
  fill: $success-color;
}

.icon.warning {
  fill: $warning-color;
}

.icon.danger {
  fill: $alert-color;
}

.icon.icon-hoverable {
  transition: $global-transition;

  &:hover {
    fill: $black;
  }
}

.close-button {
  .icon {
    position: relative;
    top: -2px;
    color: $dark-gray;

    &.delete {
      &:hover {
        color: $alert-color;
      }
    }
  }
}

.remove-button,
.edit-button,
.archive-button {
  margin-left: 0.25rem;
  .icon {
    cursor: pointer;
    position: relative;
    color: $dark-gray;
  }
  &:hover {
    color: $alert-color;
  }

  @media print {
    display: none;
  }
}
