//  React-modal (https://github.com/reactjs/react-modal)
//  ----------------------------------------------------
//

@import "../settings";

.ReactModal__Overlay {
  z-index: 100;
}
.ReactModal__Content {
  //TODO: fix css inclusion to remove !important declarations
  padding: 1rem !important;
  border-radius: $global-radius !important;
  border-color: $light-gray !important;
  box-shadow: $global-shadow;

  // Animations
  &--after-open {
    @include animate(fadeIn);
    pointer-events: all;
  }
  &--before-close {
    @include animate(fadeOut);
    pointer-events: none;
  }

  .modal-heading-row {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    margin: 1rem 0 2rem 0;
  }

  @include breakpoint(small only) {
    margin-top: $title-bar-height !important;

    .modal-heading-row {
      margin: 0.5rem 0 1rem 0;

      h3 {
        margin: 0;
        flex-shrink: 2;
      }

      .icon {
        flex-basis: 4rem;
        margin-right: 1.5rem;
      }
    }
  }

  @include breakpoint(medium) {
    min-width: 400px;
    .modal-heading-row {
      margin: 1rem 0 2rem 0;

      h3 {
        margin: 0;
        flex-shrink: 2;
      }

      .icon {
        flex-basis: 5rem;
        margin-right: 1.5rem;
      }
    }
  }

  .card {
    border: none;
  }

  .card-divider {
    padding-top: 0;
    padding-left: 2px;
    padding-right: 2px;

    label {
      width: 100%;
    }
  }
  .card-section {
    padding-left: 2px;
    padding-right: 2px;
    padding-bottom: 0;
  }
}
