// Headings
// --------
//

// Heading that also acts as navigational utility

.heading-pager {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: $global-weight-bolder;

  .button {
    margin: 0 1rem;
    border-width: 2px;
    background-color: lighten($primary-color-light, 6%);
    font-size: 18px;
    font-weight: $global-weight-bolder;

    &.disabled:hover,
    &[disabled]:hover {
      border-width: 2px;
      background-color: lighten($primary-color-light, 6%);
    }
  }
}

// Heading that also contains actions

.heading-actions {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0.5rem;

  .button {
    position: relative;
    top: 6px;
    margin: 0 0 0 1rem;
    font-size: 14px;
  }
}

// Small pile of stuff

// Heading container with extra stuff right-aligned

.heading-group-spaced {
  display: flex;
  justify-content: space-between;
  align-items: baseline;

  .footnote {
    margin-left: 1rem;
    white-space: nowrap;
  }
}

.newslist-item {
  display: block;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 1rem 0;
  color: $body-font-color;
  border-bottom: 1px solid $medium-gray;
  transition: $global-transition;

  &:hover,
  &:focus {
    color: $body-font-color;
    text-decoration: none;
    h2 {
      color: $primary-color-dark;
    }
  }

  &:last-of-type {
    border-bottom: none;
  }

  h2 {
    margin-top: 0;
  }
}
