// Tables
// ------
//

@import "../settings";

table.table {
  th,
  td {
    line-height: 16px;
    font-size: 14px;
    font-feature-settings: "tnum";
  }

  td {
    border-bottom: 1px solid $primary-color-light;
  }

  tr.align-bottom {
    vertical-align: bottom;
  }

  tr.align-top {
    vertical-align: top;
  }

  tr:last-of-type {
    td {
      border-color: transparent;
    }
  }

  thead {
    border-top: 1px solid $primary-color-dark;
    border-bottom: 1px solid $primary-color-dark;
    background-color: lighten($primary-color-light, 6%);
    //background-color: transparent;

    th {
      font-weight: $global-weight-bolder;
    }
  }

  &.scroll-small {
    @include breakpoint(small only) {
      display: block;
      overflow-y: auto;
    }
  }

  &.clickable {
    tr {
      cursor: pointer;
    }
  }

  .button {
    margin: 0;
  }
}
