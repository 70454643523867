// Alerts
// ------
//

@import "src/gfx/scss/_settings.scss";

.alert-container {
  // Container for transient & fixed alerts
  position: fixed;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 1000;

  .alert-panel {
    // Shadows only for fixed alert panels
    box-shadow: $global-shadow;
  }

  @include breakpoint(medium) {
    width: 38rem;
  }
}

.alert-panel {
  position: relative;
  display: flex;
  margin-bottom: 0.5rem;
  border-radius: $global-radius;
  background-color: $white;
  min-height: 3.5rem;

  @include breakpoint(small only) {
    flex-direction: column;
    justify-content: center;
    padding: 0.75rem 1rem 0.5rem 3.5rem;
  }

  @include breakpoint(medium) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 2rem 1rem 4.5rem;
    min-height: 4rem;
  }

  h2 {
    margin: 0;
    font-size: 16px;
    line-height: 20px;
    font-weight: $global-weight-bold;
  }

  .icon {
    position: absolute;
    top: 1rem;
    left: 1rem;
    width: 1.5rem;
    height: 1.5rem;

    @include breakpoint(medium) {
      width: 2rem;
      height: 2rem;
    }
  }

  p {
    margin-top: 0.5rem;
    font-size: 13px;
    line-height: 16px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  ul {
    margin-top: 0.5rem;

    li {
      font-size: 13px;
      line-height: 16px;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  a:not(.button) {
    text-decoration: underline;
  }

  .close-button {
    top: 0;
    right: 4px;
  }
}

.alert-content-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.alert-button-wrapper {
  text-align: center;

  @include breakpoint(small only) {
    margin: 0.5rem 0 0 0;
  }

  @include breakpoint(medium) {
    margin: 0.5rem 0 0 0;
  }

  .button {
    margin-bottom: 4px;

    &:last-of-type {
      margin-bottom: 0;
    }

    @include breakpoint(small only) {
      width: 100%;
    }
  }
}

.alert-error {
  border-color: $alert-color;
  border-style: solid;
  border-width: 1px 1px 1px 0.5rem;
  //border: 1px solid $alert-color;
  //border-left: 0.5rem solid $alert-color;

  .icon {
    color: $alert-color;
  }
}

.alert-warning {
  border-color: $warning-color;
  border-style: solid;
  border-width: 1px 1px 1px 0.5rem;

  .icon {
    color: $warning-color;
  }
}

.alert-success {
  border-color: $success-color;
  border-style: solid;
  border-width: 1px 1px 1px 0.5rem;

  .icon {
    color: $success-color;
  }
}

.alert-info {
  border-color: $primary-color;
  border-style: solid;
  border-width: 1px 1px 1px 0.5rem;

  .icon {
    color: $primary-color;
  }
}

.animate-alert-entering {
  @include animate(slideInDown);
  pointer-events: all;
}

.animate-alert-entered {
  pointer-events: all;
}

.animate-alert-exiting {
  @include animate(fadeOutUp);
  pointer-events: none;
}

.animate-alert-exited {
  display: none;
}
