// Button variations
// -----------------
//

@import "../settings";

.button {
  font-weight: $global-weight-bold;
  white-space: nowrap;

  &:active,
  &.is-active {
    background-color: scale-color($button-background, $lightness: -50%);
    box-shadow: inset 1px 1px 3px 0px rgba(0, 0, 0, 0.3);
  }

  &.tiny {
    padding: 2px 8px;
    font-size: 11px;
    line-height: 16px;
  }

  &.small {
    padding: 4px 8px;
    font-size: 13px;
    line-height: 16px;
  }

  &.large {
    font-size: 18px;
    line-height: 24px;
  }

  &.alert,
  &.warning,
  &.success,
  &.primary,
  &.primary:focus,
  &.secondary,
  &.inactive,
  &.inactive:focus {
    color: $white;

    &:hover {
      color: $white;
    }

    &:disabled {
      // Fix for Foundation bug
      color: $white;
    }
  }

  &[type="button"],
  &[type="reset"],
  &[type="submit"] {
    -webkit-appearance: none;
  }

  &.small-expanded {
    @include breakpoint(small only) {
      display: block;
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }
  }
}

.button-up {
  color: $primary-color;
  transition: $global-transition;
  height: 3rem;
  height: 3rem;
  cursor: pointer;

  &:hover {
    color: $primary-color-dark;
  }

  .icon {
    width: 2.5rem;
    height: 2.5rem;
    // Positioning for H1 inline icon
    position: relative;
    bottom: 4px;
  }
}

button:disabled {
  cursor: not-allowed;
}

.button-group {
  button.button {
    border-width: 2px;
  }
}
