@import "animate/mixins";

.animated {
  @include animate;
}

// @import "animate/bounce";
// @import "animate/bounceIn";
// @import "animate/bounceInDown";
// @import "animate/bounceInLeft";
// @import "animate/bounceInRight";
// @import "animate/bounceInUp";
// @import "animate/bounceOut";
// @import "animate/bounceOutDown";
// @import "animate/bounceOutLeft";
// @import "animate/bounceOutRight";
// @import "animate/bounceOutUp";
@import "animate/fadeIn";
// @import "animate/fadeInDown";
// @import "animate/fadeInDownBig";
// @import "animate/fadeInLeft";
// @import "animate/fadeInLeftBig";
// @import "animate/fadeInRight";
// @import "animate/fadeInRightBig";
// @import "animate/fadeInUp";
// @import "animate/fadeInUpBig";
@import "animate/fadeOut";
@import "animate/fadeOutDown";
// @import "animate/fadeOutDownBig";
// @import "animate/fadeOutLeft";
// @import "animate/fadeOutLeftBig";
// @import "animate/fadeOutRight";
// @import "animate/fadeOutRightBig";
@import "animate/fadeOutUp";
// @import "animate/fadeOutUpBig";
// @import "animate/flash";
// @import "animate/flip";
// @import "animate/flipInX";
// @import "animate/flipInY";
// @import "animate/flipOutX";
// @import "animate/flipOutY";
// @import "animate/hinge";
// @import "animate/lightSpeedIn";
// @import "animate/lightSpeedOut";
// @import "animate/pulse";
// @import "animate/rollIn";
// @import "animate/rollOut";
// @import "animate/rotateIn";
// @import "animate/rotateInDownLeft";
// @import "animate/rotateInDownRight";
// @import "animate/rotateInUpLeft";
// @import "animate/rotateInUpRight";
// @import "animate/rotateOut";
// @import "animate/rotateOutDownLeft";
// @import "animate/rotateOutDownRight";
// @import "animate/rotateOutUpLeft";
// @import "animate/rotateOutUpRight";
// @import "animate/shake";
@import "animate/slideInDown";
// @import "animate/slideInLeft";
@import "animate/slideInRight";
@import "animate/slideInUp";
@import "animate/slideOutDown";
@import "animate/slideOutLeft";
@import "animate/slideOutRight";
// @import "animate/slideOutUp";
// @import "animate/swing";
// @import "animate/tada";
// @import "animate/wiggle";
// @import "animate/wobble";
