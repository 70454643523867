// Empty state
// -----------
//

.empty-state {
  margin: 2rem auto;
  padding: 2rem 3rem;
  text-align: center;
  font-weight: $global-weight-bold;
  font-style: italic;
  color: $dark-gray;
  // border: 2px solid $medium-gray;
  // border-radius: calc(#{$global-radius} * 2);
  max-width: 32rem;
}

.table {
  .empty-state {
    border-color: transparent;
  }
}
